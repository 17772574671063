import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "../scss/main.scss";

import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import HeroSection from "../components/HeroSection";
import ServiceBox from "../components/ServiceBox";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";

import { slugify } from "../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

const ServiciosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService(sort: { fields: title_es, order: ASC }) {
        nodes {
          description_es {
            raw
          }
          title_es
          image {
            file {
              url
            }
          }
        }
      }
      allContentfulServicesPageTemplate {
        nodes {
          hero_title_es
          hero_description_es {
            hero_description_es
          }
          hero_image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const pageTitle = "Servicios | Virtual Ed Global"
  const heroTitle = data.allContentfulServicesPageTemplate.nodes[0].hero_title_es;
  const heroDescription = data.allContentfulServicesPageTemplate.nodes[0].hero_description_es.hero_description_es;
  const heroImg = data.allContentfulServicesPageTemplate.nodes[0].hero_image.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/servicios" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />

        <section className="service-boxes">
          <div className="container">
            {data.allContentfulService.nodes.map((item) => {
              return (
                <ServiceBox
                  title={item.title_es}
                  description={item.description_es}
                  img={item.image}
                  id={slugify(item.title_es)}
                  key={item.title_es}
                />
              );
            })}
          </div>
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>Aquí estamos para conversar sobre Educación Superior.</strong> Esperamos tus consultas o
                comentarios.
              </p>
            }
            button_label="Conversemos"
          />
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default ServiciosPage;
